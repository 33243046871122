// Slate 4.2.1
// Bootswatch

//
// Color system
//

$white: #d6d6d8 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #999 !default;
$gray-600: #7a8288 !default;
$gray-700: #52575c !default;
$gray-800: #3a3f44 !default;
$gray-900: rgb(33, 39, 52) !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee5f5b !default;
$orange: #fd7e14 !default;
$yellow: #f89406 !default;
$green: #62c462 !default;
$teal: #20c997 !default;
$cyan: #5bc0de !default;

$primary: $gray-800 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;

// Body
$body-bg: rgb(24, 24, 28) !default;
$body-color: #d6d6d8 !default;

// Links

$link-color: $white !default;

// Fonts

$font-size-base: 0.9375rem !default;

// Tables

$table-accent-bg: rgba($white, 0.05) !default;
$table-hover-bg: rgba($white, 0.075) !default;

$table-border-color: rgba($black, 0.6) !default;

$table-dark-border-color: $table-border-color !default;
$table-dark-color: $white !default;

// Buttons

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1rem !default;

// Forms

$input-disabled-bg: #ccc !default;

// Dropdowns

$dropdown-bg: $gray-800 !default;
$dropdown-divider-bg: rgba($black, 0.15) !default;

$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $body-bg !default;

// Navs

$nav-tabs-border-color: rgba($black, 0.6) !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color !default;

// Navbar

$navbar-padding-y: 0 !default;

$navbar-dark-hover-color: $white !default;

$navbar-light-hover-color: $gray-800 !default;
$navbar-light-active-color: $gray-800 !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: transparent !default;
$pagination-border-color: rgba($black, 0.6) !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: transparent !default;
$pagination-hover-border-color: rgba($black, 0.6) !default;

$pagination-active-bg: transparent !default;
$pagination-active-border-color: rgba($black, 0.6) !default;

$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: rgba($black, 0.6) !default;

// Jumbotron

$jumbotron-bg: darken($gray-900, 5%) !default;

// Cards

$card-border-color: rgba($black, 0.6) !default;
$card-cap-bg: lighten($gray-800, 10%) !default;
$card-bg: lighten($body-bg, 5%) !default;

// Popovers

$popover-bg: lighten($body-bg, 5%) !default;

// Modals

$modal-content-bg: lighten($body-bg, 5%) !default;

$modal-header-border-color: rgba(0, 0, 0, 0.2) !default;

// Progress bars

$progress-bg: darken($gray-900, 5%) !default;
$progress-bar-color: $gray-600 !default;

// List group

$list-group-bg: lighten($body-bg, 5%) !default;
$list-group-border-color: rgba($black, 0.6) !default;

$list-group-hover-bg: lighten($body-bg, 10%) !default;
$list-group-active-color: $white !default;
$list-group-active-bg: $list-group-hover-bg !default;
$list-group-active-border-color: $list-group-border-color !default;

$list-group-disabled-color: $gray-700 !default;

$list-group-action-color: $white !default;

// Breadcrumbs

$breadcrumb-active-color: $gray-500 !default;

// Code

$pre-color: inherit !default;
