.signin {
    &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    &--form {
        width: 400px;
    }

    &--header {
        margin-bottom: 1.5rem;
    }
}